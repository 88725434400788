// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app\\\\routes\\\\set-username.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app\\routes\\set-username.tsx");
  import.meta.hot.lastModified = "1734042404477.488";
}
// REMIX HMR END

import { redirect } from '@remix-run/cloudflare';
import { Form, useOutletContext } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { Button } from '~/components/Button';
import { Input } from '~/components/Input';
import { ACCESS_AUTHENTICATED_USER_EMAIL_HEADER } from '~/utils/constants';
import { setUsername } from '~/utils/getUsername.server';
import { useEffect, useState } from 'react';
import { getStoredPartnerConfig } from '~/utils/partner-storage';
export const action = async ({
  request
}) => {
  const url = new URL(request.url);
  const returnUrl = url.searchParams.get('return-url') ?? '/';
  const partnerId = url.searchParams.get('pId');
  const accessUsername = request.headers.get(ACCESS_AUTHENTICATED_USER_EMAIL_HEADER);
  if (accessUsername) {
    const finalUrl = new URL(returnUrl, request.url);
    if (partnerId) {
      finalUrl.searchParams.set('pId', partnerId);
    }
    throw redirect(finalUrl.toString());
  }
  const {
    username
  } = Object.fromEntries(await request.formData());
  invariant(typeof username === 'string');

  // Pass both the partner ID and return URL
  return setUsername(username, request, returnUrl, partnerId);
};
export default function SetUsername() {
  _s();
  const {
    partnerConfig
  } = useOutletContext();
  const {
    t
  } = useTranslation();
  const [storedConfig, setStoredConfig] = useState(null);
  useEffect(() => {
    const config = getStoredPartnerConfig();
    if (config) {
      setStoredConfig(config);
    }
  }, []);
  return <div className="grid h-full gap-4 place-content-center">
            <h1 className="text-3xl font-bold flex items-center gap-2">
                {storedConfig?.theme?.logo ? <img src={storedConfig.theme.logo} alt={storedConfig.name || 'Dooiu'} className="h-8 object-contain" /> : <img src="/dooiulogo.svg" alt="Dooiu" className="h-8" />}
                <span className="text-brand-primary">
                    {`${storedConfig?.name || 'Dooiu'} Meet`}
                </span>
            </h1>
            <Form className="flex items-end gap-4" method="post">
                <div className="grid gap-3">
                    <label htmlFor="username">{t('set_username_enter_display_name')}</label>
                    <Input autoComplete="off" autoFocus required type="text" id="username" name="username" />
                </div>
                <Button className="text-xs" type="submit">
                    {t('set_username_submit')}
                </Button>
            </Form>
        </div>;
}
_s(SetUsername, "AT/DQUNllaNm7ckFuQIdmoFRtY0=", false, function () {
  return [useOutletContext, useTranslation];
});
_c = SetUsername;
var _c;
$RefreshReg$(_c, "SetUsername");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;